import { graphql } from "gatsby";
import get from "lodash/get";
import React from "react";

// Layouts
import { PageLayout, SEOContent } from "layouts";

// Templates
import { MeetTemplate } from "templates";

const MeetPage = (props: any) => {
  const idx = props.data.meet.edges.length - 1;
  const meet = get(props, `data.meet.edges[${idx}].node.data`, "") || "";

  return (
    <SEOContent>
      <PageLayout full={true}>
        <MeetTemplate meet={meet} />
      </PageLayout>
    </SEOContent>
  );
};

export default MeetPage;

export const query = graphql`
  query ($meetSlug: String) {
    meet: allAirtable(filter: { fields: { meetSlug: { eq: $meetSlug } } }) {
      edges {
        node {
          data {
            meet_name
            meet_date(formatString: "ddd MMMM Do, YYYY")
            venue
            result_link
            result_pdfs_url
            result_pdfs {
              id
              url
            }
            performance_lists_url
            performance_lists {
              id
              url
            }
            meet_information_url
            meet_information {
              id
              url
            }
            meet_maps_url
            meet_maps {
              id
              url
            }
          }
        }
      }
    }
  }
`;
